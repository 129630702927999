<main-layout [selected]="'ORDERS'">
    <content>
        <!-- Orders -->
        <div *ngIf="(ordersList$ | async); else noOrders">
            <!-- The list of all oders-->
            <div class="orders-list">

                <!-- Each item -->
                <ng-container *ngFor="let order of (ordersList$ | async)">
                    <a class="order-href" [href]="'/order/' + order.id"> 
                        <div class="order-item">

                            <!-- Image -->
                            <div class="logo-main-container">
                                <div class="logo-image-container">
                                    <!-- Image -->
                                    <div class="logo-image-default">
                                        <div class="logo-icon-default">
                                            <img [src]="'apps/restaurant/src/assets/add-image-default.svg'"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <!-- Content -->
                            <div class="item-text-content">
                                
                                <!-- Content bottom -->
                                <div class="item-content-mid">
                                    <div class="item-restaurant-name">
                                        {{ order.restaurantName }}
                                    </div>
                                
                                    <div class="item-products">
                                        <ng-container *ngFor="let product of order.items; index as productIndex">
                                            {{ product.productName }}{{ productIndex === (order.items.length - 1)  ? "" : ","  }}
                                        </ng-container>
                                    </div>
                                
                                </div>
            
                                <!-- Content bottom -->
                                <div class="item-content-right">
                                    
                                    <div class="item-order-delivery-date">
                                        <span class="item-delivery-type">
                                            <!-- <img [src]="'apps/user-app/src/assets/' + ((order.deliveryOption === DeliveryOption.DELIVERY) ? 'delivery-type-bicycle-selected' : 'delivery-type-pick-up-hand-selected')  + '.svg'"/> -->
                                        </span>
                                        <span class="item-date">29. Juli, 18:32 </span> 
                                    </div>
                                    <div class="item-order-slug">
                                        #{{ order.slug }}
                                    </div>
                                    <div class="item-price">
                                        {{ order.totalPriceCents | format_cents: true }}
                                    </div>
                                </div>
                            </div>                                                    
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>

        <!-- User has no orders -->
        <ng-template #noOrders>
                
            <div class="missing-orders-container">
                <!-- Image -->
                <div class="missing-orders-image">
                    <img [src]="'apps/restaurant/src/assets/no-orders-found.jpg'"/>
                </div>

                <!-- Texts -->
                <div class="missing-orders-text-container">
                    <!-- Title -->
                    <div class="missing-orders-title">
                        <!-- Image (mobile) -->
                        <div class="missing-orders-image-mobile">
                            <img [src]="'apps/restaurant/src/assets/no-orders-found.jpg'"/>
                        </div>
                        <div class="mr-2">{{ "orders_page_no_orders_title" |  translate }}</div>
                        <mat-icon>history</mat-icon>
                    </div>
                    <!-- Body -->
                    <div class="missing-orders-body">
                        {{ "orders_page_no_orders_body" |  translate }}
                    </div>
                    <!-- Button -->
                    <ng-container *ngIf="(isVerified$ | async) === false"> 
                        <div class="missing-orders-button">
                            <!-- Button - Check restaurants -->
                            <fs-button [text]="'orders_page_no_orders_cta_1' | translate"
                                    class="button-container"
                                    [type]="'raised'" [leadingIcon]="'assignment_ind'" (onPress)="onPressVerify()">
                            </fs-button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </content>
</main-layout>

